<template>
  <v-card :loading="loading">
    <v-toolbar>
      <v-toolbar-title>Nueva solicitud de reenganche</v-toolbar-title>
    </v-toolbar>

    <div class="pa-2">
      <v-autocomplete
        label="Selecciona los contratos"
        filled
        multiple
        small-chips
        :items="contratos"
        item-value="CodigoContrato"
        clearable
        deletable-chips
        :item-text="(item) => `(${item.CodigoContrato}) ${item.denominacion}`"
        v-model="selected"
        return-object
        hide-details
      ></v-autocomplete>
    </div>

    <v-divider></v-divider>

    <v-card-text style="max-height: 600px" class="pa-2">
      <v-form ref="form">
        <v-list>
          <v-list-item v-for="item in selected" :key="item.CodigoContrato">
            <v-list-item-content>
              <v-list-item-title>
                Contrato {{ item.CodigoContrato }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.denominacion }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click.stop="enviarSolicitud"
        :disabled="selected.length == 0"
        text
        color="#19c284"
      >
        <v-icon left>mdi-link</v-icon>
        solicitar reenganche
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";
import { req } from "@/utils/validations.js";

export default {
  data() {
    const d = new Date();
    const day = ("00" + d.getDate()).slice(-2);
    const month = ("00" + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    return {
      loading: false,
      contratos: [],
      selected: [],
      today: [year, month, day].join("-"),
      rules: { req },
    };
  },
  // computed: {
  //   seleccionados() {
  //     return this.selected.map((item) => {
  //       item.deuda = this.impagados
  //         .filter((imp) => imp.CodigoContrato == item.CodigoContrato)
  //         .reduce((t, c) => c.ImporteTotal + t, 0);

  //       return item;
  //     }).sort((a, b) => b.deuda - a.deuda);
  //   },
  // },
  methods: {
    parseDate,
    async getContratos() {
      this.loading = true;
      const { data } = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
        method: "GET",
        params: {
          token: this.$store.getters.getJwtEmpresa,
        },
      });
      this.contratos = data.filter((c) => {
        return c.Situacion == 'Cortado' && c.reenganchando == 0
        } ).map(c => {
					c.denominacion= c.denominacion || c.RazonSocialTitular || [c.NombreTitular, c.Apellido1Titular, c.Apellido2Titular] .filter((i) => !!i) .join(" ");
          return c
        })

      this.loading = false;
    },
    enviarSolicitud() {
      if (!this.$refs.form.validate()) return;
      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/solicitudesReenganche.php`,
        method: "POST",
        data: {
          token: this.$store.getters.getJwtEmpresa,
          solicitudes: this.selected.map((sol) => ({
            CodigoContrato: sol.CodigoContrato,
            anulacion: 0,
            corte: 0,
          })),
        },
      })
        .then((res) => {
          this.$root.$emit("snack", "Se ha enviado la solicitud de reenganche");
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit(
            "snack",
            "No se ha podido enviar la solicitud de reenganche"
          );
        });
    },
  },
  mounted() {
    this.getContratos();
  },
};
</script>

<style></style>
